import Router from 'next/router'
import React, { useEffect } from 'react'
import { useSession } from 'components/session/session'

export default function Home() {
  const session = useSession()
  useEffect(() => {
    if (session.profile) {
      Router.replace('/clients')
    }
  }, [session.profile])
  return <></>
}